<template>
    <div class="view pa24">
        <div class="d-flex">
            <div>
                <el-input class="w200 mb10 mr10" placeholder="请输入关键字" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button>
            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column prop="memberNo" align="center" label="会员编号" show-overflow-tooltip />
                <el-table-column prop="name" align="center" label="会员名称" show-overflow-tooltip />
                <el-table-column prop="companyName" align="center" label="公司名称" show-overflow-tooltip />
                <el-table-column prop="industryName" align="center" label="所属行业" show-overflow-tooltip />
                <el-table-column prop="phone" align="center" label="联系电话" show-overflow-tooltip />
                <el-table-column prop="createTime" align="center" label="申请时间" show-overflow-tooltip />
                <el-table-column prop="endDate" align="center" label="到期时间" show-overflow-tooltip />
                <el-table-column prop="auditStatusName" align="center" label="审核状态" width="80" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div style="color:#E6A23C" v-if="scope.row.auditStatus == 0">{{ scope.row.auditStatusName }}</div>
                        <div style="color:#F56C6C" v-if="scope.row.auditStatus == 2">{{ scope.row.auditStatusName }}</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="80">
                    <template slot-scope="scope">
                        <el-button type="text" @click="showUpdateModel(scope.row)">审核</el-button>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="入团审核" :visible.sync="showModel"
            :destroy-on-close="true" width="450px" center>
            <el-form :model="formData" ref="formData" label-width="78px">
                <el-form-item label="公司名称" prop="memberName">
                    <el-input v-model="showFormData.companyName" disabled placeholder="公司名称"></el-input>
                </el-form-item>
                <el-form-item label="会员名称" prop="companyName">
                    <el-input v-model="showFormData.memberName" disabled placeholder="会员名称"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="phone">
                    <el-input v-model="showFormData.phone" disabled placeholder="会员名称"></el-input>
                </el-form-item>
                <el-form-item label="所属行业" prop="industryName">
                    <el-input v-model="showFormData.industryName" disabled placeholder="会员名称"></el-input>
                </el-form-item>
                <el-form-item label="会员属性" prop="memberType">
                    <el-radio-group v-model="showFormData.memberType" disabled>
                        <el-radio-button v-for="(name, id) in memberTypeData" :key="id" :label="id">{{ name }}
                        </el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="会员类型" prop="memberLevel">
                    <el-select v-model="showFormData.memberLevel" disabled placeholder="请选择类型">
                        <el-option v-for="(name, id) in memberLevel" :key="id" :label="name" :value="String(id)">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm(1)">通过</el-button>
                <el-button type="danger" @click="submitForm(2)">不通过</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getAssociationMemberLevelList, updateAssociationMember, getAssociationMemberList } from "@/api/associationMember";
export default {
    name: "groupingTable",
    components: {
        commonTable
    },
    data() {
        return {
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [],
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            //模态框配置
            showFormData: {
                memberName: "",
                phone: "",
                companyName: "",
                memberType: 0,
                memberLevel: "",
                industryName: "",

            },
            formData: {
                memberId: 0,
                auditStatus: 0,
            },
            formType: "",
            associationId: 0,
            memberLevel: {},
            memberTypeData: { 0: '企业', 1: '个人' },
            memberStatusData: { 0: "正常", 1: "过期", 2: "禁用" },
            auditStatusData: { 0: "待审核", 1: "通过", 2: "不通过" }
        };
    },
    filters: {

    },
    watch: {
        watchAssociation(row) {
            this.associationId = row.associationId;
            this.currentPage = 1;
            this.getAssociationMemberLevelList();
            this.getList();
        }
    },
    computed: {
        watchAssociation() {
            return this.$store.state.checkedAssociation;
        }
    },
    created() {
        let associationId = JSON.parse(sessionStorage.getItem("checkedAssociation")).associationId;
        this.associationId = associationId;
        this.getAssociationMemberLevelList();
        this.getList();
    },
    methods: {
        /**@method 获取字典数据 */
        getAssociationMemberLevelList() {
            getAssociationMemberLevelList({ associationId: this.associationId, levelType: 0 }).then(res => {
                if (res.code === 200) {
                    let memberLevel = {};
                    for (let row of res.data) {
                        memberLevel[row.memberLevel] = row.levelName;
                    }
                    this.memberLevel = memberLevel;
                }
            })
        },
        /**@method 搜索 */
        searchFun() {
            this.getList();
        },
        /**@method 获取列表 */
        async getList(payload) {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                showPass: 1,
                associationId: this.associationId,
                showExport: false,
            };
            try {
                this.loading = true;
                let res = await getAssociationMemberList(params)
                this.loading = false;

                const { data } = res;
                let tableData = [];
                for (let row of data.pageInfo.list) {
                    row.auditStatusName = this.auditStatusData[row.auditStatus]
                    tableData.push(row);
                }
                this.tableData = data.pageInfo.list;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        /**@method 编辑
         * @param {Object} val - 当前点击行的值
         */
        showUpdateModel(row) {
            this.showModel = true;
            this.formType = "update";
            this.showFormData = {
                memberName: row.name,
                companyName: row.companyName,
                memberType: row.memberType,
                memberLevel: row.memberLevel,
                industryName: row.industryName,
                phone: row.phone,
            };
            this.formData = {
                memberId: row.memberId,
                auditStatus: 0,
            };
        },
        submitForm(status) {
            this.formData.auditStatus = status;
            updateAssociationMember(this.formData).then(res => {
                if (res.code === 200) {
                    this.getList();
                    this.$message({
                        type: "success",
                        message: "审核成功"
                    })
                    this.showModel = false;
                } else {
                    this.$message.error(res.message)
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            })
        },

    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>